import { FC, ReactNode } from "react";
import Flex from "./Flex";

interface ContainerProps {
  direction?: "row" | "column";
  justify?:
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "start"
  | "end";
  align?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const Container: FC<ContainerProps> = ({
  direction = "row",
  align = "stretch",
  className = "",
  justify = "center",
  children,
  style,
  ...props
}) => {
  const containerProps = {
    display: "flex",
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    marginbottom: "120px",
    width: "100vw",
    ...style,
  };


  return (
    <Flex style={containerProps} className={className} {...props}>
      <Flex style={{ width: "70%", justifyContent: "center", alignItems: "center" }}>
        {children}
      </Flex>
    </Flex>
  );
};
