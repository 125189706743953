import { useEffect, useState } from "react";
import Button from "../../custom/Button";
import Flex from "../../custom/Flex";
import ImageFirstSection from "./ImageFirstSection";
import pic1 from "./../../assets/pic1.png";
import pic2 from "./../../assets/pic2.png";
import pic3 from "./../../assets/pic3.png";
import pic4 from "./../../assets/pic4.png";
import pic5 from "./../../assets/pic5.png";
import pic6 from "./../../assets/pic6.png";

export const FirstSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      direction="row"
      style={{
        width: "100vw",
        height: "95vh",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Flex
        style={{
          width: isMobile ? "100%" : "50vw",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          direction="column"
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: isMobile ? "40px" : "80px",
              lineHeight: isMobile ? "50px" : "100px",
              color: "#064E3B",
              marginBottom: isMobile ? "0px" : "-15px",
            }}
          >
            Vivez mieux avec
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: isMobile ? "40px" : "80px",
              lineHeight: isMobile ? "50px" : "100px",
              color: "#064E3B",
              marginTop: isMobile ? "0px" : "-15px",
              marginBottom: "30px",
            }}
          >
            Disconnect
          </div>
          <div
            style={{
              fontSize: "30px",
              color: "#059669",
            }}
          >
            Une application de bien-être numérique avec une IA intégrée
          </div>
          <div
            style={{
              fontSize: "20px",
              color: "gray",
              marginTop: "15px",
            }}
          >
            Reconnectez-vous au monde réel
          </div>
          <Button
            style={{
              padding: "20px 30px",
              marginTop: "45px",
              backgroundColor: "#65A30D",
              color: "white",
              border: "none",
              fontWeight: "bold",
              width: !isMobile ? "100%" : "50%",
              justifyContent: "center",
            }}
            title={!isMobile ? "Télécharger maintenant" : "Télecharger"}
          />
        </Flex>
      </Flex>

      {!isMobile && (
        <Flex
          direction="row"
          style={{
            width: "50vw",
            height: "100%",
            alignItems: "center",
            justifyContent: "end",
            marginRight: "50px",
          }}
        >
          <Flex>
            <Flex
              style={{
                width: "330px",
                marginRight: "15px",
                marginTop: "20px",
                marginBottom: "-20px",
              }}
            >
              <ImageFirstSection imageUrls={[pic3, pic5, pic6]} />
            </Flex>
            <Flex
              style={{
                width: "330px",
                marginRight: "20px",
                marginTop: "-20px",
                marginBottom: "20px",
              }}
            >
              <ImageFirstSection imageUrls={[pic4, pic1, pic2]} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
