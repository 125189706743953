import React, { useEffect, useState } from "react";
import Button from "../custom/Button";
import Flex from "../custom/Flex";
import { Zap } from 'lucide-react';

const NavBar: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1400);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Flex
      className="flex justify-between items-center p-4 w-full h-50"
      align="center"
      style={{ borderBottom: "solid lightgray" }}
    >
      <Flex style={{ width: "250px" }} className="text-black text-xl font-bold">
        <Zap style={{ marginRight: "10px" }} />
        Disconnect
      </Flex>
      {!isMobile && <Flex className="hidden md:flex space-x-8">
        <a href="home" className="text-black text-base hover:text-green-200">
          Accueil
        </a>
        <a href="propos" className="text-black text-base hover:text-green-200">
          À propos
        </a>
        <a href="team" className="text-black text-base hover:text-green-200">
          Équipe
        </a>
      </Flex>}
      <Button
        style={{ justifyContent: "center", alignItems: "center" }}
        title={!isMobile ? "Commencer maintenant" : "Commencer"}
        className="text-black px-4 py-2 hover:text-green-200 hover:border-green-500"
      />
    </Flex>
  );
};

export default NavBar;
