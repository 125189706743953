import Text from "../../custom/Text";
import Flex from "../../custom/Flex";
import { Card } from "./Card";
import { useEffect, useState } from "react";

export const FourthSection = () => {
  type TCards = {
    title: string;
    description: string;
  };

  const cards: TCards[] = [
    {
      title: "Suggestions personnalisées",
      description:
        "Notre IA apprend de vos préférences pour vous proposer des activités que vous aimerez.",
    },
    {
      title: "Localisation intelligente",
      description:
        "Recevez des suggestions basées sur votre localisation actuelle.",
    },
    {
      title: "Communauté",
      description:
        "Rejoignez une communauté d'utilisateurs partageant les mêmes intérêts.",
    },
    {
      title: "Amélioration continue",
      description:
        "Notre IA s'améliore avec le temps pour vous proposer des suggestions de plus en plus pertinentes.",
    },
    {
      title: "Respect de la vie privée",
      description:
        "Vos données sont traitées localement et ne sont jamais partagées.",
    },
    {
      title: "Défis et activités",
      description:
        "Recevez des défis et activités pour vous divertir et améliorer votre bien-être.",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1400);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      style={{
        backgroundColor: "#064E3B",
        justifyContent: "center",
        alignItems: "center",
        width:"100%"
      }}
    >
      <Flex direction="column" style={{ width: "100%", alignItems: "center", justifyContent:"center", marginTop:"80px" }}>
        <Text size={40} style={{ fontWeight: "bold", color: "white" }}>
          Avantages
        </Text>
        <Text size={22} style={{ color: "white", textAlign: !isMobile ? "start" : "center" }}>
          Découvrez les avantages de notre application.
        </Text>
        <Flex
          style={{
            marginTop: "100px",
            marginBottom:"100px",
            width: "80%",
            gap: 50,
            justifyContent: !isMobile ? "space-between" : "center",
            flexWrap: "wrap",
          }}
        >
          {cards.map((card, index) => (
            <Flex key={index}>
              <Card title={card.title} description={card.description} />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
