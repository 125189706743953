import React from "react";
import Flex from "../../custom/Flex";

interface ImageContainerProps {
  imageUrls: string[];
}

export const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrls,
}) => {
  return (
    <Flex direction="column" align="center">
      {imageUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Image ${index + 1}`}
          style={{
            borderRadius: "15px",
            marginBottom: "10px",
            width: "100%",
            height: "100%",
          }}
        />
      ))}
    </Flex>
  );
};

export default ImageContainer;
