import { FC, useEffect, useState } from "react";
import Flex from "../../custom/Flex";
import Text from "../../custom/Text";
import { Box } from "lucide-react";

type TCard = {
  title: string,
  description: string,
}

export const Card: FC<TCard> = ({ title, description }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <Flex direction="row" style={{ width: isMobile ? "200" : "400px", minWidth:"200px", gap:15 }}>
    {!isMobile && <Flex style={{ height: "100%", width: "10%" }}>
      <Box size={30} color="white" />
    </Flex>}
    <Flex direction="column" style={{ width: "100%", marginTop: "-15px", justifyContent: "center", alignItems:isMobile ? "center" : "start" }}>
      <Text size={30} style={{ color: "white", textAlign: !isMobile ? "start" : "center" }}>{title}</Text>
      <br />
      <Text size={20} style={{ color: "white", textAlign: !isMobile ? "start" : "center" }}>{description}</Text>
    </Flex>
  </Flex>;
};
