import Button from "../../custom/Button";
import { Container } from "../../custom/Container";
import Flex from "../../custom/Flex";
import Text from "../../custom/Text";

export const ThirdContainer = () => {
  return (
    <Container
      style={{ alignItems: "center", justifyContent: "center", marginTop: "70px", marginBottom:"70px" }}
    >
      <Flex
        direction="column"
        style={{
          width: "100%",
          backgroundColor: "#DCFCE7",
          alignItems: "center",
          gap: 10,
          padding: "30px",
          borderRadius: "15px",
          justifyContent: "center",
        }}
      >
        <Flex direction="column" justify="center" align="center">
          <Text style={{ fontWeight: "bold", textAlign: "center" }} size={58}>
            Améliorez votre bien-être avec
          </Text>
          <Text style={{ fontWeight: "bold" }} size={58}>
            nous
          </Text>
        </Flex>
        <Text style={{textAlign:"center"}} size={22}>
          Expérimentez une nouvelle façon de vivre votre temps libre
        </Text>
        <Button
          style={{
            padding: "20px 30px",
            justifyContent: "center",
            backgroundColor: "#65A30D",
            color: "white",
            border: "none",
            marginTop: "25px",
            fontWeight: "bold",
          }}
          title="Rejoignez nous !"
        />
      </Flex>
    </Container>
  );
};
