import Flex from "../custom/Flex";
import NavBar from "./../components/Nav";
import { FirstSection } from "../components/firstSection/FirstSection.jsx";
import { SecondSection } from "../components/secondSection/SecondSection";
import { ThirdContainer } from "../components/thirdSection.tsx/ThirdSection";
import { FourthSection } from "../components/fourthSection/FourthSection";
import { FifthSection } from "../components/fifthSection/FifthSection.jsx";

export const Home = () => {
  return (
    <Flex direction="column">
      <NavBar />
      <FirstSection />
      <SecondSection />
      <ThirdContainer />
      <FourthSection />
      <FifthSection />
    </Flex>
  );
};
