import { useEffect, useState } from "react";
import { Container } from "../../custom/Container";
import Flex from "../../custom/Flex";
import Text from "../../custom/Text";

export const SecondSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Container >
      <Flex
        direction="column"
        style={{
          width: "100%",
          minWidth: "40%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Flex direction="column">
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "64px",
              lineHeight: "100px",
              color: "#064E3B",
              textAlign: "center",
            }}
          >
            Comment ça marche ?
          </Text>
        </Flex>
        <Flex
          direction="column"
          style={{
            marginTop: "80px",
            gap: 50,
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Flex
            style={{
              alignItems: "center",
              borderBottom: "solid 1px #059669",
              width: "80%",
            }}
          >
            {!isMobile && <Text style={{ fontSize: "120px", fontWeight: "bold" }}>1</Text>}
            <Flex direction="column" style={{ marginLeft: !isMobile ? "80px" : "0px", gap: 10, marginBottom:"50px" }}>
              <Text style={{ fontWeight: "bold", textAlign: "center" }} size={24}>
                Etape 1: Téléchargez
              </Text>
              <Text style={{ color: "#059669", textAlign: isMobile ? "center" : "start" }} size={22}>
                Téléchargez l'application et créez votre profil en fonction de
                vos préférences
              </Text>
            </Flex>
          </Flex>

          <Flex
            style={{
              alignItems: "center",
              borderBottom: "solid 1px #059669",
              width: "80%",
            }}
          >
            {!isMobile && <Text style={{ fontSize: "120px", fontWeight: "bold" }}>2</Text>}
            <Flex direction="column" style={{ marginLeft: !isMobile ? "80px" : "0px", gap: 10, marginBottom: "50px" }}>
              <Text style={{ fontWeight: "bold", textAlign:"center" }} size={24}>
                Etape 2: Découvrez
              </Text>
              <Text style={{ color: "#059669", textAlign: isMobile ? "center" : "start" }} size={22}>
                L'IA analyse vos habitudes et suggère des activités adaptées à
                vos goûts
              </Text>
            </Flex>
          </Flex>

          <Flex
            style={{
              alignItems: "center",
              borderBottom: "solid 1px #059669",
              width: "80%",
            }}
          >
            {!isMobile && <Text style={{ fontSize: "120px", fontWeight: "bold" }}>3</Text>}
            <Flex direction="column" style={{ marginLeft: !isMobile ? "80px" : "0px", gap: 10, marginBottom: "50px" }}>
              <Text style={{ fontWeight: "bold", textAlign: "center" }} size={24}>
                Etape 3: Profitez
              </Text>
              <Text style={{ color: "#059669", textAlign: isMobile ? "center" : "start" }} size={22}>
                Profitez d'une vie plus équilibrée grâce à des suggestions
                personnalisées
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
