import { useEffect, useState } from "react";
import Text from "../../custom/Text";
import Flex from "../../custom/Flex";
import big from "./../../assets/five/big.png";
import first from "./../../assets/five/first.png";
import second from "./../../assets/five/second.png";

export const FifthSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1400);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex style={{ height: "100vh", width: "100%" }}>
      {!isMobile && (
        <Flex
          direction="row"
          style={{
            width: isMobile ? "100%" : "50%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
          }}
        >
          <Flex style={{ height: "50%" }}>
            <img
              src={big}
              style={{
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
            ></img>
          </Flex>
          <Flex direction="column" style={{ height: "25%", gap: 15 }}>
            <img
              src={first}
              style={{
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
            ></img>
            <img
              src={second}
              style={{
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
            ></img>
          </Flex>
        </Flex>
      )}
      <Flex
        direction="column"
        style={{
          width: isMobile ? "100%" : "50%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          size={50}
          style={{ color: "#064E3B", fontWeight: "bold", textAlign: "center" }}
        >
          Découvrez notre
        </Text>
        <Text
          size={50}
          style={{ color: "#064E3B", fontWeight: "bold", marginBottom: "30px" }}
        >
          application
        </Text>
        <Text size={24} style={{ color: "#059669", textAlign: "center" }}>
          Explorez les fonctionnalités et découvrez comment elle peut améliorer
          votre bien-être
        </Text>
      </Flex>
    </Flex>
  );
};
