import React from "react";
import Flex from "./Flex";

type buttonProps = {
  style?: React.CSSProperties;
  title?: string;
  className?: string;
};

export const Button: React.FC<buttonProps> = ({
  title = "button",
  className = "",
  style,
}) => {
  return (
    <Flex
      justify="center"
      align="center"
      style={{
        padding: "10px 20px",
        cursor: "pointer",
        border: "solid #064E3B",
        borderRadius: 8,
        ...style,
      }}
      className={className}
    >
      {title}
    </Flex>
  );
};

export default Button;
